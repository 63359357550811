.contacts .hero {
    background-color: #000000;
}

.contacts .content .section-content {
    max-width: 1400px;
    margin-bottom: 60px !important;
}

.contacts .content .section-content .section {
    margin-top: 45px;
}

.contacts .content, .about .content .section-content, .about .content .section-content .section {
    padding-left: 0;
    padding-right: 0;
}

.contacts .content .section-content .section .title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    max-width: 100%;
    color: #c28a3f;
}

.contacts .content .section-content form .form.success {
    color: #c28a3f;
    margin-top: 25px;
}

.contacts .content .section-content .section .title.black {
    text-transform: initial;
}

.contacts .content .section-content .section .subtitle {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 900;
}

.contacts .content .section-content .section .description {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.5px;
}

.contacts .content .section-content .section a.description {
    color: unset
}

.contacts .content .section-content .section label p {
    font-size: 18px;
    line-height: 1.23;
    letter-spacing: 1.5px;
    margin-top: 20px;
}

.contacts .content .section-content .section label input[type="text"], .contacts .content .section-content .section label textarea {
    border-radius: 1px;
    border: solid 2px #FFFFFF;
    font-size: 14px;
    line-height: 1.23;
    letter-spacing: 1.5px;
    background-color: #FFFFFF;
    margin-top: 10px;
    width: 80%;
    padding: 8px;
    resize: none;
}

.contacts .content .section-content .section input[type="submit"] {
    display: block;
    text-transform: uppercase;
    border-radius: 1px;
    border: solid 2px #FFFFFF;
    font-size: 18px;
    letter-spacing: 1.5px;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    background-color: #FFFFFF;
    margin-top: 20px;
}

/* flexboxgrid >= sm */

@media only screen and (min-width: 48em) {
    .contacts .hero {
        height: 45vh;
    }
    .contacts .content {
        padding-left: 8.33333333%;
        padding-right: 8.33333333%;
        padding-bottom: 200px;
    }
    .contacts .content .section-content .section:nth-child(2n) {
        padding-left: 21px;
    }
    .contacts .content .section-content .section:nth-child(3n+1) {
        padding-right: 21px;
    }
    .contacts .content .section-content .section {
        margin-top: 85px;
    }
    .contacts .content .section-content .section .title {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.73;
        letter-spacing: 3px;
    }
    .contacts .content .section-content .section .subtitle {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.79;
        letter-spacing: 5px;
    }
    .contacts .content .section-content .section .description {
        font-size: 20px;
        line-height: 1.32;
        letter-spacing: 1.5px;
    }
    .contacts .content .section-content .section label p {
        font-size: 22px;
    }
    .contacts .content .section-content .section label input[type="text"], .contacts .content .section-content .section label textarea {
        font-size: 18px;
        width: 100%;
    }
    .contacts .content .section-content .section input[type="submit"] {
        font-size: 22px;
    }
}

/* flexboxgrid >= md */

@media only screen and (min-width: 64em) {
    .contacts .content .section-content .section .title {
        font-size: 30px;
    }
}

/* flexboxgrid >= lg */

@media only screen and (min-width: 75em) {
    .contacts .content .section-content .section .title {
        font-size: 36px;
    }
}