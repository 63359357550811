.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    z-index: 900;
    transition: 0.25s all ease-in-out;
}

.header.scrolling {
    background-color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header .menu-bar .logo {
    z-index: 900;
    padding-left: 16px;
}

.header .menu-bar .logo img {
    width: 150px;
}

.header .menu-bar .menu-icon {
    z-index: 900;
    padding-right: 16px;
}

.header .menu-bar .navigation {
    display: none;
}

.header .menu-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 500;
    backdrop-filter: blur(6px);
    background-color: rgba(5, 21, 31, 0.76);
    transition: visibility 0.25s linear, opacity 0.25s linear;
    opacity: 0;
    visibility: hidden;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 900;
    line-height: 2.22;
    letter-spacing: 1.06px;
    text-transform: uppercase;
}

.header .menu-content .section {
    margin-top: 37px;
}

.header .menu-content.open {
    opacity: 1;
    visibility: visible;
}

.header .navigation a {
    text-decoration: none;
    text-transform: uppercase;
    color: #ffffff;
}

.header .navigation a.active .section {
    opacity: 0.59;
}

.header .navigation .section {
    cursor: pointer;
}

.header .menu-content.navigation p {
    display: flex;
    align-items: inherit;
}

.header .menu-content.navigation a {
    opacity: 1;
}

.header .menu-content .submenu p.section {
    display: block;
    width: 100%;
    text-align: center;
}

.header .menu-content .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.header .menu-content .submenu.open {
    max-height: 100vh;
}

.header .navigation p i {
    margin-left: 5px;
    margin-bottom: 3px;
    transition: all 0.25s ease-in-out;
}

.header i.open {
    margin-bottom: 0px !important;
    transform: rotateZ(-135deg);
}

.header .submenu .section {
    font-weight: normal;
    margin-top: 6px;
}

.header .menu-bar .burger {
    height: 3em;
    width: 3em;
    position: relative;
    font-size: 12px;
    cursor: pointer;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
    -webkit-tap-highlight-color: transparent;
}

.header .menu-bar .burger .burger-lines:after {
    left: 0;
    top: -1em;
}

.header .menu-bar .burger .burger-lines:before {
    left: 1em;
    top: 1em;
}

.header .menu-bar .burger:after {
    content: "";
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
}

.header .menu-bar .burger .burger-lines {
    top: 50%;
    margin-top: -0.125em;
}

.header .menu-bar .burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
    pointer-events: none;
    display: block;
    content: "";
    width: 100%;
    border-radius: 0.25em;
    background-color: white;
    height: 0.25em;
    position: absolute;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.header .menu-bar .burger .burger-lines:after {
    left: 0;
    top: -1em;
}

.header .menu-bar .burger .burger-lines:before {
    left: 0em;
    top: 1em;
}

.header .menu-bar .burger.burger-rotate .burger-lines:after,
.burger.burger-rotate .burger-lines:before {
    width: 2em;
}

.header .menu-bar .burger.burger-rotate .burger-lines,
.burger.burger-rotate .burger-lines:after,
.burger.burger-rotate .burger-lines:before {
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.header .menu-bar .burger.burger-rotate.open {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.header .menu-bar .burger.burger-rotate.open .burger-lines {
    background-color: transparent;
}

.header .menu-bar .burger.burger-rotate.open .burger-lines:before,
.burger.burger-rotate.open .burger-lines:after {
    left: 0.5em;
    top: 0px;
}

.header .menu-bar .burger.burger-rotate.open .burger-lines:before {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.header .menu-bar .burger.burger-rotate.open .burger-lines:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.header .menu-bar .burger {
    float: right;
}

/* flexboxgrid >= md */

@media only screen and (min-width: 64em) {
    .header .menu-bar .logo {
        padding-left: 70px;
    }
    .header .menu-bar .menu-icon {
        display: none;
    }
    .header .menu-bar .navigation {
        display: flex;
    }
    .header .menu-content {
        display: none;
    }
    .header .menu-bar .navigation .section {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.26px;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        z-index: 900;
    }
    .header .menu-bar .navigation a {
        z-index: 900;
    }
    .header .menu-bar .navigation .submenu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 400;
        background-color: #05151f;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
    }
    .header .menu-bar .navigation .submenu.open {
        max-height: 100vh;
    }
    .header .menu-bar .navigation .submenu .section:first-child {
        margin-top: 12vh;
    }
    .header .menu-bar .navigation .submenu .section:not(.title) {
        font-size: 20px;
        letter-spacing: 0.38px;
        font-weight: bold;
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 40px;
        text-transform: initial;
    }
    .header .menu-bar .navigation .submenu .section.title {
        color: rgba(255, 255, 255, 0.6);
        cursor: default;
    }
}
