.product .carousel-fullscreen-container.mask.backdrop-blur {
    background-color: rgba(0, 0, 0, 0.9);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    .product .carousel-fullscreen-container.mask.backdrop-blur {
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

.product .hero {
    background-color: #000000;
}

.product .content,
.product .content div {
    padding-left: 0;
    padding-right: 0;
}

.product .content .first-content {
    max-width: 700px;
}

.product .message {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.5px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 4%;
    margin-right: 4%;
}

.product .hero .message {
    color: #ffffff;
}

.product .image-container {
    max-width: 600px;
    max-height: 600px;
}

.product .image {
    max-width: 600px;
    max-height: 600px;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.product .content .logo-container img {
    max-width: 25%;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;
    margin-bottom: 25px;
}

.product .gallery.row {
    max-width: 1400px;
}

.product .gallery {
    width: 100%;
    margin-top: 25px !important;
}

.product .gallery .title {
    text-transform: uppercase;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: 4.45px;
    color: #c28a3f;
    margin-left: 0;
    margin-right: 0;
    padding-left: 4%;
    padding-right: 4%;
}

.product .gallery .carousel-container {
    margin-top: 20px;
}

.product .gallery .carousel-container > div > div {
    margin-left: 0px;
    margin-right: 0px;
}

.product .gallery .carousel-container .carousel-image {
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 95%;
    padding-top: 130%;
    margin-bottom: 10px;
}

.product .gallery .carousel-container .slick-next {
    background-image: url("/img/arrow_right_orange.svg");
    background-position: center;
    background-repeat: no-repeat;
    top: -36px;
    right: 1.5%;
    height: 32px;
    cursor: pointer;
}
.product .gallery .carousel-container .slick-prev {
    background-image: url("/img/arrow_left_orange.svg");
    background-position: center;
    background-repeat: no-repeat;
    top: -36px;
    right: 5%;
    left: unset;
    height: 32px;
    cursor: pointer;
}
.product .gallery .carousel-container .slick-disabled {
    opacity: 0.25;
}

.product .gallery .carousel-container .slick-arrow:before {
    content: "";
}

.product .gallery .carousel-fullscreen-container.mask {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.25s linear, opacity 0.25s linear;
}

.product .gallery .carousel-fullscreen-container.mask.visible {
    opacity: 1;
    visibility: visible;
}

.product .gallery .carousel-fullscreen-container .carousel {
    padding: 10%;
    position: relative;
}

.product .gallery .carousel-fullscreen-container .carousel-image-item {
    margin: auto;
    max-width: 90vw;
    max-height: 90vh;
}

.product .gallery .carousel-fullscreen-container .exit-icon {
    margin: 32px;
    align-self: flex-end;
}

/* flexboxgrid >= sm */

@media only screen and (min-width: 48em) {
    .product .hero {
        height: 60vh;
    }
    .product .content {
        padding-left: 8.33333333%;
        padding-right: 8.33333333%;
    }
    .product .content .first-content.first-sm {
        padding-right: 16px;
    }
    .product .content .first-content.first-xs {
        padding-left: 16px;
    }
    .product .message {
        font-size: 20px;
        margin-left: 0;
        margin-right: 0;
        font-weight: 600;
        line-height: 1.55;
        letter-spacing: 1.29px;
    }
    .product .content {
        padding-top: 60px !important;
    }
    .product .content .image-container {
        margin-bottom: 30px;
    }
    .product .first-xs .image-container {
        margin-top: 25%;
    }
    .product .gallery .title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

/* custom images position */
#external_env_1_product_image_1 {
    background-size: 1050px;
    background-position-x: -280px;
}
#external_env_1_product_image_2 {
    background-position-x: -180px;
    background-size: 780px;
}
#external_env_2_product_image_1 {
    background-size: 900px;
    background-position-x: -330px;
}
#external_env_2_product_image_2 {
}
#external_env_3_product_image_1 {
    background-size: 1000px;
    background-position-x: -460px;
}
#external_env_3_product_image_2 {
    background-size: 1000px;
}
#external_env_4_product_image_1 {
}
#external_env_4_product_image_2 {
    background-size: 1020px;
    background-position-x: -440px;
}
#internal_env_1_product_image_1 {
}
#internal_env_1_product_image_2 {
    background-size: 990px;
    background-position-x: -460px;
}
#internal_env_2_product_image_1 {
    background-size: 860px;
    background-position-x: -320px;
}
#internal_env_2_product_image_2 {
    background-size: 630px;
    background-position-x: -40px;
}
#internal_env_3_product_image_1 {
    background-size: 1450px;
    background-position-y: -330px;
    background-position-x: -540px;
}
#internal_env_3_product_image_2 {
    background-size: 1020px;
    background-position-y: -160px;
}
#internal_env_4_product_image_1 {
}
#internal_env_4_product_image_2 {
}

#external_env_1_media_1 {
    background-size: 215%;
}
#external_env_1_media_2 {
}
#external_env_1_media_3 {
    background-position: right;
}
#external_env_1_media_4 {
    background-position-x: -210px;
}
#external_env_1_media_5 {
    background-position: left;
}
#external_env_1_media_6 {
    background-position-x: -210px;
    background-size: 950px;
}
#external_env_1_media_7 {
    background-position-x: -50px;
}
#external_env_1_media_8 {
    background-position-x: -39px;
}
#external_env_1_media_9 {
    background-size: 215%;
}
#external_env_1_media_10 {
    background-position-x: -63px;
}
#external_env_1_media_11 {
}
#external_env_1_media_12 {
    background-position-x: -276px;
}

#external_env_2_media_1 {
}
#external_env_2_media_2 {
}
#external_env_2_media_3 {
    background-position-x: left;
}
#external_env_2_media_4 {
}
#external_env_2_media_5 {
}
#external_env_2_media_6 {
    background-position-x: -30px;
    background-size: 870px;
}
#external_env_2_media_7 {
}
#external_env_2_media_8 {
}
#external_env_2_media_9 {
    background-size: 314%;
    background-position-y: 0px;
    background-position-x: -649px;
}
#external_env_2_media_10 {
    background-size: 1190px;
    background-position-x: -180px;
    background-position-y: 0px;
}
#external_env_2_media_11 {
}
#external_env_2_media_12 {
    background-position-x: -200px;
}

#external_env_3_media_1 {
}
#external_env_3_media_2 {
}
#external_env_3_media_3 {
    background-position-x: -49px;
}
#external_env_3_media_4 {
    background-position-x: -40px;
}
#external_env_3_media_5 {
}
#external_env_3_media_6 {
}
#external_env_3_media_7 {
    background-position-y: -20px;
    background-size: 860px;
}
#external_env_3_media_8 {
}
#external_env_3_media_9 {
    background-position-x: -30px;
}
#external_env_3_media_10 {
}
#external_env_3_media_11 {
}
#external_env_3_media_12 {
    background-position-x: -360px;
    background-position-y: 0px;
    background-size: 960px;
}

#external_env_4_media_1 {
    background-position-x: -50px;
    background-size: 320%;
    background-position-y: -40px;
}
#external_env_4_media_2 {
    background-size: 800px;
}
#external_env_4_media_3 {
    background-position-x: -100px;
    background-size: 270%;
    background-position-y: -10px;
}
#external_env_4_media_5 {
    background-size: 220%;
}
#external_env_4_media_6 {
    background-size: 140%;
}
#external_env_4_media_7 {
}
#external_env_4_media_8 {
    background-size: 1220px;
    background-position-x: -90px;
    background-position-y: -30px;
}
#external_env_4_media_9 {
}
#external_env_4_media_10 {
}
#external_env_4_media_11 {
    background-position-x: -170px;
}
#external_env_4_media_12 {
    background-position-x: -280px;
    background-size: 230%;
}
#external_env_4_media_4 {
    background-position-x: -40px;
}

#internal_env_1_media_1 {
}
#internal_env_1_media_2 {
    background-position-x: -380px;
    background-size: 740px;
}
#internal_env_1_media_3 {
    background-position-x: -170px;
}
#internal_env_1_media_4 {
    background-position-x: -250px;
}
#internal_env_1_media_5 {
    background-position-x: -250px;
}
#internal_env_1_media_6 {
    background-position-x: -20px;
}
#internal_env_1_media_9 {
    background-position-x: -380px;
    background-size: 740px;
}

#internal_env_2_media_1 {
}
#internal_env_2_media_2 {
}
#internal_env_2_media_3 {
}
#internal_env_2_media_4 {
}
#internal_env_2_media_5 {
}
#internal_env_2_media_6 {
}

#internal_env_3_media_1 {
}
#internal_env_3_media_2 {
    background-position-x: -220px;
    background-size: 220%;
}
#internal_env_3_media_3 {
    background-position-x: -290px;
}
#internal_env_3_media_4 {
}
#internal_env_3_media_5 {
}
#internal_env_3_media_6 {
    background-position-x: -410px;
    background-size: 250%;
}

#internal_env_4_media_1 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_2 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_3 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_4 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_5 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_6 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_7 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_8 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_9 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_10 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_11 {
    background-size: 100%;
    background-color: #fff;
}
#internal_env_4_media_12 {
    background-size: 100%;
    background-color: #fff;
}
