.footer {
    font-size: 12px;
    letter-spacing: 0.5px;
    width: 100%;
}

.footer .content .section .title {
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
}

.footer .content .section p {
    margin-top: 13px;
}

.footer .content .section {
    padding-top: 20px;
}

.footer .content .section:last-child {
    padding-top: 0px;
}

.footer .content .section img {
    margin-top: 13px;
}

.footer .content .section img:nth-child(2) {
    margin-left: 30px;
}

.footer .content .image-container {
    display: flex;
    align-items: center;
}

.footer .content .section:last-child {
    padding-bottom: 30px;
}

.footer .content .section .subtitle {
    opacity: 0.6;
}

/* flexboxgrid >= sm */

@media only screen and (min-width: 48em) {
    .footer .content .section {
        padding-top: 0px;
    }
    .footer .content {
        margin-top: 25px !important;
    }
}

/* flexboxgrid >= md */

@media only screen and (min-width: 64em) {
    .footer {
        font-size: 14px;
        letter-spacing: 2.33px;
    }
    .footer .content {
        padding-left: 8.33333333%;
        padding-right: 8.33333333%;
    }
    .footer .content .sections-content {
        max-width: 1400px;
    }
    .footer .content .section img {
        margin-top: 25px;
    }
    .footer .content .section p {
        margin-top: 20px;
    }
    .footer .content .section .title {
        font-size: 17px;
        letter-spacing: 4px;
    }
}

/* flexboxgrid >= lg */

@media only screen and (min-width: 75em) {
    .footer .section {
        padding-bottom: 70px;
        padding-top: 70px;
    }
    .footer .section.last {
        padding-top: 0px;
    }
}