.hero {
    width: 100vw;
    height: 90vh;
    color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.hero .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.50;
    background-color: #000000;
}

.hero .hidden {
    display: none;
}

.hero .constraint-parent {
    position: absolute;
    width: 100%;
    height: 100%;
}

.hero .constraint-parent.center {
    display: flex;
    justify-content: center;
    align-content: center;
}

.hero .constraint-parent.bottom {
    display: flex;
    justify-content: flex-end;
}

.hero .constraint-parent.column {
    flex-direction: column;
}

.hero .title {
    font-size: 34px;
    font-weight: lighter;
    line-height: 1.18;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 25px;
    margin-left: 2%;
    margin-right: 2%;
}

.hero .subtitle {
    font-size: 16px;
    letter-spacing: 0.94px;
    text-align: center;
    margin-left: 2%;
    margin-right: 2%;
}

.hero .message {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.71px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.hero .logo-container {
    margin-bottom: 20px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.hero .logo-container img {
    max-width: 30%;
    margin-left: 5px;
    margin-right: 5px;
}

/* flexboxgrid >= md */

@media only screen and (min-width: 64em) {
    .hero .title {
        font-size: 60px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.03;
        letter-spacing: 1.52px;
    }
    .hero .subtitle {
        font-size: 22px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
    }
    .hero .message {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.31px;
    }
    .hero .logo-container img {
        max-width: 50%;
        margin-left: 40px;
        margin-right: 40px;
    }
}

/* flexboxgrid >= sm */

@media only screen and (min-width: 48em) {
    .hero .title {
        margin-left: 15%;
        margin-right: 15%;
    }
    .hero .subtitle {
        margin-left: 15%;
        margin-right: 15%;
    }
}