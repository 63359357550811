body {
    margin: 0;
    font-family: 'Overpass', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f3ed !important;
}

code {
    font-family: 'Overpass', sans-serif;
}

p {
    margin: 0;
}

.row {
    margin: 0px !important;
}

i {
    border: solid #FFFFFF;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.pointer {
    cursor: pointer;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* slightly transparent fallback */
.backdrop-blur {
    background-color: rgba(0, 0, 0, 0.9);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
    .backdrop-blur {
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
    }
}