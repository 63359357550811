.home>.content {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.home>.content p {
    width: 100%;
}

.home .hero .logo-container img {
    width: 250px;
}

.home>.content .title {
    font-size: 32px;
    font-weight: 200;
    line-height: 1.06;
    letter-spacing: 0.4px;
    margin-left: 4%;
    margin-right: 4%;
}

.home>.content .subtitle {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-top: 25px;
    margin-left: 4%;
    margin-right: 4%;
}

.home>.content .list {
    overflow-x: auto;
    list-style: none;
    white-space: nowrap;
    padding: 0;
    width: 100%;
    text-align: center;
}

.home>.content .list .listItem {
    display: inline-block;
    position: relative;
    width: 277px;
    margin-left: 1%;
    margin-right: 1%;
    vertical-align: top;
    white-space: normal;
    text-align: left;
}

.home>.content .list .listItem .image {
    padding-top: 75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.25s ease-in-out;

}

.home>.content .list .listItem .image:hover {
    transform: scale(0.98);
}

.home>.content .list .listItem .title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-left: 0px;
    text-align: center;
}

.home>.content .list .listItem .subtitle {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    opacity: 0.75;
    margin-top: 5px;
    margin-left: 0px;
    text-align: center;
}

.home>.content .list .listItem:first-child {
    margin-left: 4%;
}

.home>.content .list .listItem:last-child {
    margin-right: 4%;
}

/* flexboxgrid >= sm */

@media only screen and (min-width: 48em) {
    .home .content>p.col-sm-offset-2 {
        margin-left: 16.66666667%;
        margin-right: 16.66666667%;
    }
    .home>.content .list .listItem .image {
        padding-top: 100%;
    }
}

/* custom images position */
#external_env_1_hero {
    background-position-x: right;
    background-position-y: -20px;
    background-size: 500px;
}
#external_env_2_hero {
    background-size: 400px;
}
#external_env_3_hero {
    background-size: 400px;
    background-position-x: -40px;
}
#external_env_4_hero {
    background-size: 500px;
    background-position-x: -170px;
}
#internal_env_1_hero {
    background-size: 860px;
    background-position-x: -110px;
    background-position-y: -80px;
}
#internal_env_2_hero {
    background-size: 680px;
    background-position-y: -80px;
}
#internal_env_3_hero {
    
}
#internal_env_4_hero {
    
}