.about .hero {
    background-color: #000000;
}

.about .content .section-content {
    max-width: 1400px;
    margin-bottom: 60px !important;
}

.about .content .section-content .section {
    margin-top: 45px;
}

.about .content, .about .content .section-content, .about .content .section-content .section {
    padding-left: 0;
    padding-right: 0;
}

.about .content .section-content .section .image {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100%;
}

.about .content .section-content .section .title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.08;
    letter-spacing: 4.45px;
    color: #c28a3f;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 45px;
}

.about .content .section-content .section .description {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.5px;
    margin-left: 4%;
    margin-right: 4%;
}

.about .content .logo-container img {
    margin: 10px;
}

/* flexboxgrid >= sm */

@media only screen and (min-width: 48em) {
    .about .hero {
        height: 60vh;
    }
    .about .content {
        padding-left: 8.33333333%;
        padding-right: 8.33333333%;
    }
    .about .content .section-content .section .title {
        font-size: 55px;
        line-height: 1.04;
        letter-spacing: 5px;
        margin-left: 0;
        margin-right: 0;
    }
    .about .content .section-content .section .description {
        font-size: 20px;
        line-height: 1.32;
        letter-spacing: 1.1px;
        margin-left: 0;
        margin-right: 0;
    }
    .about .content .section-content .section:nth-child(2n) {
        padding-left: 21px;
    }
    .about .content .section-content .section:nth-child(3n+1) {
        padding-right: 21px;
    }
    .about .content .section-content .section {
        margin-top: 45px;
    }
}

/* flexboxgrid >= md */

@media only screen and (min-width: 64em) {
    .about .content .section-content .section {
        order: initial;
    }
}